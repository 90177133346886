<template>
  <el-table :data="data" row-key="id" border>
    <el-table-column type="expand">
      <template slot-scope="props">
        <div class="expandCon">
          <el-row
            :gutter="10"
            v-for="(item, indexc) in props.row.orderGoods"
            :key="indexc"
          >
            <el-col :span="7">
              <img :src="'/api/' + item.image" class="tablePic fl" />
              <span class="goodsName">
                {{ item.name }}
              </span>
            </el-col>
            <el-col :span="6">
              <span class="font-gray">单价：</span>￥{{ item.price }}
            </el-col>
            <el-col :span="6">
              <span class="font-gray">数量：</span>{{ item.count }}
            </el-col>
            <el-col :span="5">
              <span class="font-gray">总价：</span>￥{{ item.subtotal }}
            </el-col>
          </el-row>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="id" label="订单号"> </el-table-column>
    <el-table-column prop="createtime" label="订单时间"> </el-table-column>
    <el-table-column prop="storeName" label="店铺"> </el-table-column>
    <el-table-column prop="purchaser" label="买家"> </el-table-column>
    <el-table-column prop="createtime" label="支付状态">
      <template slot-scope="scope">
        <span v-html="getOrderStatus(scope.row)"> </span>
      </template>
    </el-table-column>
    <el-table-column prop="totalPrice" label="订单总额" width="100">
    </el-table-column>
    <el-table-column prop="orderType" label="订单类型" width="80">
      <template slot-scope="scope">
        <span :class="scope.row.orderType > 0 ? 'font-blue' : ''">
          {{ scope.row.orderType | orderTypeFilter }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="participantsStatus" label="拼团状态" width="100px">
      <template slot-scope="scope">
        <span :class="scope.row.orderType > 0 ? 'font-blue' : ''">
          {{
            scope.row.orderType == 0
              ? ''
              : scope.row.participantsStatus == 0
              ? '拼团中'
              : '拼团成功'
          }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="distributionMode" label="配送方式">
      <template slot-scope="scope">
        <span :class="scope.row.distributionMode">
          {{ scope.row.distributionMode | statusFilter }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="price" label="实际支付" width="100">
    </el-table-column>
    <el-table-column prop="purchaser" label="操作" width="320">
      <template #default="{ row }">
        <el-link
          type="primary"
          class="m-r-10"
          :underline="false"
          @click="onJump(row.id)"
        >
          详情
        </el-link>
        <el-link
          :disabled="canDeliver(row)"
          type="primary"
          class="m-r-10"
          :underline="false"
          @click="onDeliver(row)"
        >
          发货
        </el-link>
        <el-link
          :disabled="canRefund(row)"
          class="m-r-10"
          type="primary"
          :underline="false"
          @click="onRefund(row.id)"
        >
          退款
        </el-link>
        <el-link
          class="m-r-10"
          type="danger"
          :disabled="canRefundManual(row)"
          :underline="false"
          @click="onRefundManual(row.id)"
        >
          主动退款
        </el-link>
        <el-link
          class="m-r-10"
          type="primary"
          :underline="false"
          @click="onPrint(row)"
        >
          小票
        </el-link>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  filters: {
    statusFilter(value) {
      const map = ['物流快递', '商家配送', '用户自提', '外卖'];
      return map[value];
    },
    orderTypeFilter(value) {
      const map = ['正常订单', '团长下单', '团员下单', '未知'];
      return map[value];
    },

    filterStatus(value) {
      const map = [
        { color: '#909399', text: '待支付' },
        { color: '#409EFF', text: '待发货' },
        { color: '#67C23A', text: '已发货' },
        { color: '#909399', text: '已收货' },
        { color: '#909399', text: '已取消' }
      ];
      return map[value].text;
    }
  },
  props: { data: Array },
  methods: {
    // 状态
    getOrderStatus(obj) {
      const orderStatusMap = [
        { color: '#909399', text: '待支付' },
        { color: '#409EFF', text: '待发货' },
        { color: '#67C23A', text: '已发货' },
        { color: '#909399', text: '已收货' },
        { color: '#909399', text: '已取消' }
      ];
      const refundStatusMap = [
        { color: '#909399', text: '未申请' },
        { color: '#F00', text: '申请退款' },
        { color: '#F00', text: '拒绝退款' },
        { color: '#909399', text: '退款中' },
        { color: '#909399', text: '已退款' }
      ];
      if (obj.refundStatus !== 0) {
        return (
          "<span style='color:" +
          orderStatusMap[obj.orderStatus].color +
          ";margin-right:10px'>" +
          orderStatusMap[obj.orderStatus].text +
          '</span>' +
          '<span style=color:' +
          refundStatusMap[obj.refundStatus].color +
          '>' +
          refundStatusMap[obj.refundStatus].text +
          '</span>'
        );
      } else {
        return (
          '<span style=color:' +
          orderStatusMap[obj.orderStatus].color +
          '>' +
          orderStatusMap[obj.orderStatus].text +
          '</span>'
        );
      }
    },
    // 能发货
    canDeliver(row) {
      const can = row.orderStatus == 1 && row.refundStatus < 3;
      return !can;
    },
    // 能退货
    canRefund(row) {
      const can = row.refundStatus == 1;
      return !can;
    },
    // 能主动退货
    canRefundManual(row) {
      const can = row.refundStatus == 0 && row.orderStatus == 1;
      return !can;
    },
    // 能核销
    canWrite(row) {
      const can = row.orderStatus == 2;
      return !can;
    },
    onJump(id) {
      this.$emit('jump', id);
    },
    onDeliver(row) {
      this.$emit('deliver', row);
    },
    onRefund(id) {
      this.$emit('refund', id);
    },
    onRefundManual(id) {
      this.$emit('refund-manual', id);
    },
    onPrint(row) {
      this.$emit('print', row);
    },
    onWrite(id) {
      this.$emit('write', id);
    }
  }
};
</script>
